<template>

<v-img class="bg-white" src="@/assets/img/disclaimer.jpg" cover></v-img>

  <!--<div class="position-relative" :style="`background: #1A237E; background-size: cover; background-position: 50%;`">
    <span class="mask opacity-2"></span>

    <v-card-text class="text-center text-white font-weight-bold pb-0">
      <p class="text-center text-white text-h4 mb-0">
        Aiwin智勝 免責聲明
      </p>
      <p class="text-left text-white text-body mb-0">
        歡迎您蒞臨瀏覽智勝官方網站（以下簡稱本公司、本網站）所提供的各
        項產品及服務相關資訊，當您進入本網站及瀏覽各網頁時，即表示您同
        意遵守下述條款。在使用本網站前，請認真閱讀以下法律聲明。如不接
        受本聲明，請立即停止使用本網站，否則將視為全部接受本聲明。
      </p>
    </v-card-text>
    <v-container fluid>
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="text-left">
          <p class="text-left text-typo text-body">
            <b>一、基本原則</b><br />
            本網站所載的所有資料、商標、標誌、圖像、數據、規格、短片、聲音
            檔案、連結及其他資料等（以下簡稱資料），僅供參考之用，本網站有
            權隨時更改，修訂，更新資料。資料之更改係由本公司單方決定，並不
            作事先另行通知。雖然本網站已盡力確保內容資料準確性，但仍可能有
            技術上的誤差或排印上的錯誤，本網站不會明示，默示或隱含保證該等
            資料均為準確無誤。本網站不會對任何錯誤或遺漏承擔任何法律責任。
            任何人士使用本網頁而引致之任何損害（包括但不限於電腦病毒、系統
            故障、資料損失）、誹謗、侵犯版權或知識產權所造成的損失，包括但
            不限於利潤、商譽、使用、資料損失或其他無形損失，本公司不承擔任
            何法律責任及任何直接、間接、附帶、特別、衍生性或懲罰性賠償。
          </p>
          <p class="text-left text-typo text-body">
            <b>二、智慧財產權</b><br />
            本網站的所有資料屬智勝公司及其關係企業所有，均受智慧財產權法所
            保障，任何未經授權所使用的資料均屬侵權行為。在未經本網站明確同
            意下，本網站資料之全部或部份均不可被使用、複印、銷售、傳送、修
            改、發表、儲存或以其他方式利用作任何用途。
          </p>
          <p class="text-left text-typo text-body">
            <b>三、責任限制</b><br />
            就此內容上或任何產品、資訊或服務，而招致的任何損失或損害負上任
            何責任（不論基於合約、侵權行為（包括疏忽）或其他方面的）。在不
            限制以上條文的一般性之原則及任何情況下，本網站亦不須就進入或使
            用本網站的任何附帶引起、特殊或相應而生之任何種類損害作出賠償，
            包括但不限於利潤損失、營業受阻、營業資料損失或其他經濟損失、人
            身傷害或死亡。本網站所提供的服務，可能會出現中斷、終止、故障、
            遭受網路駭客攻擊導致資料內容竄改或消失等情形，可能會造成使用上
            的不便、資料錯誤或損失，本網站亦不負任何賠償責任。本網站不會提
            供任何與本網站有關之明示、默示、暗示性等保證，這包括適用性、權
            利或不侵權的明示或暗示責任，及其他對用途、品質、銷售、商業利益
            與軟體相容性或權利存在等問題。本網站及其內容可能為其他外間網站
            所推介或由其超連結所接達。用戶應注意外間網站所載的資料是由該等
            機構編纂及發布。對於其他外間網站的內容、該等網站所載有關本網站
            的任何資訊或超連結或該等網站的任何變動或更新，本網站概不負責。
            本網站對於該等外間網站所引起或與其有關的任何損失或損害，無須負
            上任何責任。本網站可能會連結至其他機構所提供的網頁或程式，但這
            些網頁或程式並不是由本網站所控制。本網站不會對這些網頁或程式所
            顯示的內容作出任何保證或承擔任何責任。如瀏覽這些網頁或程式，使
            用者須自行承擔後果。且在任何情況下，本公司對於本網站的用戶及由
            其他網站超連結到本網站的用戶選用網站內資訊、數據或程式、下載作
            任何用途而招致直接、間接、特別及衍生的損害，一律不負任何責任。
          </p>
          <p class="text-left text-typo text-body">
            <b>四、一般性賠償</b><br />
            因違反本免責聲明及、使用本網站服務的關係或原因，令本公司及關係
            企業招致或蒙受任何損失、索償、法律責任、損害、要求、費用及支出
            （包括所有的律師費用），需依民法規定負擔損害責任。
          </p>
          <p class="text-left text-typo text-body">
            <b>五、管轄法律</b><br />
            本免責聲明如有未盡事宜，應依中華民國法令之規定辦理，如有相關爭
            議，以臺灣臺北地方法院為管轄法院或依法令所指定法院為管轄法院。
          </p>
          <p class="text-left text-typo text-body">
            <b>六、免責聲明更新</b><br />
            本網站有修改或更正網頁各項資訊及本免責聲明之權利但無義務，且無
            須表明任何理由。修改或更正後之資訊及聲明將公佈於本網站，不再另
            行個別通知。每次瀏覽本網站前，煩請務必查看此免責聲明。如繼續使
            用本網站，即代表同意接受更改後的免責聲明約束。
          </p>
          <p class="text-left text-typo text-body">
            <b>七、語言版本</b><br />
            本網站資料若語言版本有差異之處，悉依中文版本為準。
          </p>
        </v-card-text>

      </v-card>
    </v-container>
  </div>-->
</template>
<script>
export default {
  name: "getting-started",
};
</script>
